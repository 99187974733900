import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { OperateType, UserStatus, Strategy, } from '../../../../typings/organization/org-manage';
import moment from 'moment';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
let OrgInfo = class OrgInfo extends Vue {
    constructor() {
        super(...arguments);
        this.OperateType = OperateType;
        this.UserStatus = UserStatus;
        // 主部门的验证规则
        this.mainDepartRules = [
            { required: true, message: '主部门不能为空', trigger: 'change' },
        ];
        // 邮箱的验证规则
        this.emailRules = [
            {
                validator: value => value
                    ? /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)
                    : true,
                message: '请输入正确的邮箱！',
                trigger: 'blur',
            },
        ];
        // 员工编号的验证规则
        this.employeeNoRules = [
            {
                validator: value => /^[a-zA-Z0-9]{1,30}$/.test(value),
                message: '支持大小写英文和数字，最大长度为30个字！',
                trigger: 'blur',
            },
        ];
        // 电话号码的验证规则
        this.phoneRules = [
            {
                validator: value => /^[0-9-]{8,13}$/.test(value),
                message: '请输入正确的办公电话！',
                trigger: 'blur',
            },
        ];
    }
    get isShowPartTimeDept() {
        return this.operateType === OperateType.Edit &&
            ((this.isEdit && !this.isAdd) || !!this.userOrgInfo.partTimeDeparts.length);
    }
    onEditChange(v) {
        if (v && !this.isAdd && !this.userOrgInfo.partTimeDeparts?.length) {
            this.addPartTimeDept();
        }
    }
    /**
     * 清空主部门或兼职部门的同时也清空主部门上级或兼职部门上级
     */
    delStaffChange(key) {
        if (key === 'mainDepartSuperior') {
            this.userOrgInfo[key].splice(0, 1);
        }
        else {
            const [partTimeDepartsKey, index] = key.split('_');
            this.userOrgInfo.partTimeDeparts[index][partTimeDepartsKey].splice(0, 1);
        }
        this.onStaffSelectorChange();
    }
    /**
     * 选人控件选项个数变化时，需要更改主部门和兼职部门的禁止项
     */
    onStaffSelectorChange() {
        this.$parent.$parent.$parent.setStaffSelectorDisabledKeys();
    }
    getMomentString(date) {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    }
    addPartTimeDept() {
        // 当前兼职部门不能选择主部门和其它兼职部门
        const partTimeDepartDisabledKeys = [
            this.userOrgInfo.mainDepart[0]?.id,
            ...(this.userOrgInfo.partTimeDeparts?.map((item) => item.partTimeDepart[0]?.id)),
        ];
        const partTimeDept = {
            // 兼职部门
            partTimeDepart: [],
            partTimeDepartDisabledKeys: partTimeDepartDisabledKeys,
            partTimeDepartPath: '',
            // 兼职部门上级
            partTimeDepartSuperior: [],
            partTimeRandomId: `part-time-depart-${+new Date()}-${Math.floor(Math.random() * 10000000000)}`,
        };
        this.userOrgInfo.partTimeDeparts.push(partTimeDept);
    }
    minusPartTimeDept(index) {
        this.userOrgInfo.partTimeDeparts.splice(index, 1);
    }
    // 主部门直属上级, 兼职部门直属上级
    get staffSelectorParams() {
        return {
            orgId: this.userOrgInfo.orgInfo.id,
            resourceCode: Strategy.UserManageCode,
        };
    }
};
__decorate([
    Prop({ default: false })
], OrgInfo.prototype, "isAdd", void 0);
__decorate([
    Prop({ default: false })
], OrgInfo.prototype, "isEdit", void 0);
__decorate([
    Prop()
], OrgInfo.prototype, "userOrgIndex", void 0);
__decorate([
    Prop({ default: '' })
], OrgInfo.prototype, "userId", void 0);
__decorate([
    Prop({
        default: () => {
            return {};
        },
    })
], OrgInfo.prototype, "multipleOrgParams", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], OrgInfo.prototype, "operateType", void 0);
__decorate([
    Prop({ default: () => { } })
], OrgInfo.prototype, "userOrgInfo", void 0);
__decorate([
    Watch('isEdit', { immediate: true })
], OrgInfo.prototype, "onEditChange", null);
OrgInfo = __decorate([
    Component({
        name: 'OrgInfo',
        components: {
            CForm: () => import('@/components/shared/CForm/Form'),
            CFormItem: () => import('@/components/shared/CForm/FormItem'),
            CDropDown: () => import('./drop-down-value.vue'),
            StaffSelector,
        },
    })
], OrgInfo);
export default OrgInfo;
